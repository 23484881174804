
























































  import {Component, Vue} from "vue-property-decorator";
  import quoteDialog from './component/quoteDialog.vue'

  @Component({})
  export default class knowledgeElement extends Vue {

    private subjectArr: any = [];
    private options: any = [{}, {}, {}];
    private subjectName = '';
    private knowledgeData = []
    private formLabelWidth = '100px'
    private dialogFormVisible = false
	  private currentNode:any = {}
	  private knowledgeEleName = ''
	  private currentId = ''
	  private defaultExpandNodes = []
	  private defaultExpendKeys: any = []

    private nodeExpend(draggingNode: any, dropNode: any, dropType: any) {
      let id: any = null
      // this.defaultExpendKeys = []
      id = dropNode.data.id
      this.defaultExpendKeys.push(id)
    }
    private nodeCollapse(nodes, node, self) {
      let id: any = null
      id = node.data.id
      this.defaultExpendKeys = this.defaultExpendKeys.filter(i => i !== id)
    }
    private async getSubject() {
      const res: any = await this.axios.post('/system/dictionary/querySubject');
      if (res.result) {
        Object.keys(res.json).forEach((key, index) => {
          let subjectParName = ''
          switch (key) {
            case 'high':
              subjectParName = '高中'
              break;
            case 'middle':
              subjectParName = '初中'
              break;
            case 'primary':
              subjectParName = '小学'
              break;
            default:
              subjectParName = '小学'
              break;
          }
          this.$set(this.options[index], 'value', key)
          this.$set(this.options[index], 'label', subjectParName)
          this.$set(this.options[index], 'children', res.json[key])
        });
        this.options.forEach((item: any, index: number) => {
          item.children.forEach((key: any) => {
            this.$set(key, 'value', key.code)
            this.$set(key, 'label', key.name)
          });
        });
        this.options = this.options.filter(i => i.children && i.children.length !== 0)
        this.subjectArr[0] = this.options[0].value;
        this.subjectArr[1] = this.options[0].children[0].value;
        setTimeout(() => {
          this.getKnowledgeEle(this.subjectArr[1]);
        })
      }
    };

    private append(data) {
      this.dialogFormVisible = true;
      this.currentNode = data
    };

    private edit(data) {
      this.dialogFormVisible = true;
      this.currentNode = data;
      this.knowledgeEleName = data.name;
      this.currentId = data.id;
    };
    //打开章节
    private quoteChapter() {
      this.$modal.create({
        component: quoteDialog,
        title: '引用章节',
        width: 450,
	      props: { subjectCode: this.subjectArr[1], title:'章节' }
      }).$on('close', () => {
        this.getKnowledgeEle(this.subjectArr[1]);
      })
    }
    //打开知识点
    private quoteKnowledge() {
      this.$modal.create({
        component: quoteDialog,
        title: '引用知识点',
        width: 450,
	      props: { subjectCode: this.subjectArr[1], title:'知识点' }
      }).$on('close', () => {
        this.getKnowledgeEle(this.subjectArr[1]);
      })
    }
    // 拖拽
    private defaultKeys = [] //默认展开
    private nodeDrop(draggingNode, dropNode, dropType, ev) {
      let data
      if(dropNode.data.pid === '0') {
        if(dropType ==='inner') {
          draggingNode.data.pid = dropNode.data.id
          data = dropNode.data.childs
          this.defaultKeys = data
        }else {
          draggingNode.data.pid = '0'
          data = dropNode.parent.data
          this.defaultKeys = data
        }
      }else {
        if(dropType ==='inner') {
          draggingNode.data.pid = dropNode.data.id
          data = dropNode.data.childs
          this.defaultKeys = dropNode.parent.data
        }else {
          draggingNode.data.pid = dropNode.parent.data.id
          data = dropNode.parent.data.childs
          this.defaultKeys = data
        }
      }
      this.axios.post('/admin/knowledgeElement/updateSort', data , { headers: {'Content-Type':'application/json'}}).then((res: any) => {
        this.getKnowledgeEle(this.subjectArr[1])
        if(res.result) {
          this.$message({message: '保存成功', type: 'success' })
          this.dialogFormVisible = false;
        }else{
          this.$message({message: res.msg, type: 'error' });
        }
      });
    }
    private addOrUpdateKnowledge(currentId) {
      if(currentId){
        this.saveChapterInfo(this.currentId, this.knowledgeEleName, this.currentNode.pid)
      }else {
        this.saveChapterInfo(this.currentId, this.knowledgeEleName, this.currentNode.id, this.subjectArr[1])
      }

    };

    private saveChapterInfo(id = '', knowledgeEleName, pid = '0', subjectId?, sortNum?) {
        console.log(pid,'pidpidpidpidpidpidpidpidpidpidpidpidpidpid')
      this.axios.post('/admin/knowledgeElement/saveOrUpdate', { id, name: knowledgeEleName, pid, subjectId, sortNum}).then((res: any) => {
        this.getKnowledgeEle(this.subjectArr[1])
        if(res.result) {
          this.$message({message: '保存成功', type: 'success' })
          this.dialogFormVisible = false;
        }else{
          this.$message({message: res.msg, type: 'error' });
        }
      });
    }

    private async remove(node, {id}) {
      let confirm = await this.$confirm('此操作将永久删除该知识元, 是否继续?', '提示', { type: 'warning' });
      if (confirm) {
        const res: any = await this.axios.post('/admin/knowledgeElement/batchDeleteByParentId',{id: id});
        res.result && this.$message.success('删除成功~！') && this.getKnowledgeEle(this.subjectArr[1])
      };
    }

    private getKnowledgeEle(subjectId) {
      setTimeout(() => {
        const cascaderREF: any = this.$refs['cascader'];
        this.subjectName = cascaderREF.presentText.split('/')[1];
        this.axios.post('/admin/knowledgeElement/queryTree', {subjectId}).then((res: any) => {
          if(res.result) {this.knowledgeData = res.json;}
        })
      })
    };

    private down(data) {
      this.moveUpOrDown(data.id, -1);
    };

    private up(data) {
      this.moveUpOrDown(data.id, 1);
    };

    private moveUpOrDown(knowledgeId, moveType) {
      this.axios.post('/tiku/knowledge/moveUpOrDownKnowledge', {knowledgeId, moveType}).then((res: any) => {
        this.getKnowledgeEle(this.subjectArr[1])
      })
    };

    private subjectChange() {
      this.getKnowledgeEle(this.subjectArr[1]);
    }

    private created() {
      this.getSubject()
    };

    private mounted() {
      let dom: any = document.getElementsByClassName('container')[0];
      dom.style.background = '#F4F5F9';
      dom.style.boxShadow = 'none';
      dom.style.border = 'none';
      dom.style.height = "100%"
    }

    private destroyed() {
      let dom: any = document.getElementsByClassName('container')[0];
      dom.style.background = '';
      dom.style.boxShadow = '';
      dom.style.border = '';
      dom.style.height = ""
    }
  }
