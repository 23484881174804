












import {Component, Prop, Vue} from "vue-property-decorator";
import Subject from "../grade.vue";

@Component({})
export default class knowledgeElement extends Vue{
  @Prop({ default: '' }) subjectCode!: string
  @Prop() title?: string
   constructor(props) {
      super(props);
    }
  private treeProps = { label: 'name', children: 'childs' }
  private count = 1
  private materialValue: any = []
  private materialoptions: any = []
  private chapterData: any = []

  private created(){
    this.geMaterialtData()
  }
  // 获取数据 
  private async geMaterialtData() {
    if(this.title==='章节') {
      const res: any = await this.axios.post('/tiku/bookVersion/queryBookVersionBySubjectCode', {subjectCode: this.subjectCode});
      if(res.result){
        this.materialoptions = res.json
        this.materialoptions.map((item: any, index: number) => {
          item.label = item.name
          item.value = item.id
          item.children = item.bookVersions
          if(item.bookVersions != null){
            item.children.map(key => {
              key.label = key.name
              key.value = key.id
            })
          }
        });
        this.materialValue[0] = this.materialoptions[0].value;
        if(this.materialoptions[0].children != null){
          this.materialValue[1] = this.materialoptions[0].children[0].value;
          this.axios.post('/tiku/bookChapter/queryTreeByBookVersion', { bookId: this.materialValue[1] }).then((res: any) => {
            this.chapterData = res.json;
          })
        }
      }
    }else {
      const res: any = await this.axios.post('/tiku/knowledge/queryTree', {subjectId: this.subjectCode});
      if(res.result) {
        this.chapterData = res.json
      }
    }
  
  }
  // 级联改变时
  private handleChange(data){
    if(data.length > 1){
      this.axios.post('/tiku/bookChapter/queryTreeByBookVersion', { bookId: data[1]} ).then((res: any) => {
        this.chapterData = res.json;
      })
    }
  }

  // 选中节点
  private formData: any = []
  private handleCheck(target) {
    let flag = true
    this.formData.map((v, index) => {
      if(v.id === target.id) {
        this.formData.splice(index, 1)
        flag = false
        return
      }
    })
    if(flag) {
      this.formData.push(target)
    }
  }
  private save() {
    if(this.formData.length !== 0){
      let saveData: any = []
      this.formData.map((item, index) => {
        if(item.childs.length > 0) {
          item.child = item.childs
          item.subjectId = this.subjectCode
          saveData.push(item)
        }else {
          item.subjectId = this.subjectCode
          saveData.push(item)
        }
      })
      this.axios.post('/admin/knowledgeElement/save',  saveData , { headers:{ "Content-Type": 'application/json' }} ).then((res: any) => {
        if(res.result) {
          this.$message({message: '引用成功', type: 'success' });this.$emit('close')
        }else {
          this.$message({message: res.msg, type: 'error' });
        }
      }) 
    }else {
      this.$message({message: '请选择'+this.title, type: 'warning' });
    }
  }
   
}
